<template>
	<div class="_form">
		<div class="Stats_title">
			<h3>美巡赛</h3>
			<h3>数据排行榜</h3>
		</div>
		<div class="outside">
			<!-- 1 -->
			<div class="modes">
				<div class="mode">
					<div class="title">赛事前十次数 
						<el-tooltip class="item" effect="dark"  placement="top-start">
							<template slot="content">
								<p class="tooltip-content">球员在本赛季的美巡官方赛事中进入前10名的总次数</p>
							</template>
							<span class="iconfont icon-xiangqing ml10"></span>
						</el-tooltip>
					</div>
					<div class="table_list">
						<statsList :staId="'138'" :title="''" :title2="'前十次数'" />
					</div>
					<div class="get_more">
						<a @click="toSeeMore('138','赛事前十次数 TOP 10 FINISHES')">> 查看更多</a>
					</div>
				</div>
				<div class="mode">
					<div class="title">平均成绩
						<el-tooltip class="item" effect="dark"  placement="top-start">
							<template slot="content">
								<p  class="tooltip-content">（仅限美巡赛）这是考虑了阵容平均杆数之后的加权平均成绩。它的计算方法是把球员的总杆数与一个调整数相加，再除以比赛的总轮数。调整数是基于阵容每轮的平均值计算得出的：标准杆减去平均值得出每轮的调整数。球手每轮成绩加上这些调整数而得出最终成绩</p>
							</template>							
							<span class="iconfont icon-xiangqing ml10"></span>
						</el-tooltip>						
					</div>
					<div class="table_list">
						<statsList :staId="'120'" :title="''" :title2="'平均数'" />
					</div>
					<div class="get_more">
						<a @click="toSeeMore('120','平均成绩 SCORING AVERAGE')">> 查看更多</a>
					</div>
				</div>
			</div>
			<div class="shadow-line"></div>
			<!-- 2 -->
			<div class="modes">
				<div class="mode">
					<div class="title">开球距离
						<el-tooltip class="item" effect="dark"  placement="top-start">
							<template slot="content">
								<p class="tooltip-content">每一次测得的开球平均码数。这些开球是在每轮的两个洞测量的。经过审慎考虑而选择两个相反方向的球洞以抵消风的影响。</p>
							</template>							
							<span class="iconfont icon-xiangqing ml10"></span>
						</el-tooltip>						
					</div>
					<div class="table_list">
						<statsList :staId="'101'" :title="''" :title2="'平均数'" />
					</div>
					<div class="get_more">
						<a @click="toSeeMore('101','开球距离 DRIVING DISTANCE')">> 查看更多</a>
					</div>
				</div>
				<div class="mode">
					<div class="title">上球道率
						<el-tooltip class="item" effect="dark" content="上球道率" placement="top-start">
							<template slot="content">
								<p class="tooltip-content">开球停留在球道上的百分比（无论是什么球杆）</p>
							</template>						
							<span class="iconfont icon-xiangqing ml10"></span>
						</el-tooltip>						
					</div>
					<div class="table_list">
						<statsList :staId="'102'" :title="''" :title2="'%'" />
					</div>
					<div class="get_more">
						<a @click="toSeeMore('102','上球道率 DRIVING ACCURACY PERCENTAGE')">> 查看更多</a>
					</div>
				</div>
			</div>
			<div class="shadow-line"></div>
			<!-- 3 新加6项 -->
			<div class="modes">
				<div class="mode">
					<div class="title">标准杆上果岭率
						<el-tooltip class="item" effect="dark" content="标准杆上果岭率" placement="top-start">
							<template slot="content">
								<p class="tooltip-content">球员能够以标准杆打上果岭的百分比。备注：只要在标准杆上果岭的击球后，球的任何部位碰触到了推杆表面就视作打上了果岭。标准杆上果岭的击球为标准杆减去两杆（三杆洞的第一杆、四杆洞的第二杆，以及五杆洞的第三杆）。</p>
							</template>
							<span class="iconfont icon-xiangqing ml10"></span>
						</el-tooltip>						
					</div>
					<div class="table_list">
						<statsList :staId="'103'" :title="''" :title2="'%'" />
					</div>
					<div class="get_more">
						<a @click="toSeeMore('103','标准杆上果岭率 GREENS IN REGULATION PERCENTAGE')">> 查看更多</a>
					</div>
				</div>
				<div class="mode">
					<div class="title">发球台到果岭得分
						<el-tooltip class="item" effect="dark"  placement="top-start">
							<template slot="content">
								<p class="tooltip-content">在同一球场和赛事中，球手每轮平均杆数高于或低于阵容平均值并减去球手的推杆得分。得分的概念是美巡赛Shotlink智能项目的副产品，该项目鼓励学者们对Shotlink的数据进行研究。哥伦比亚商学院的Mark Broadie教授提出了早期概念，后来由美巡赛进行了完善。</p>
							</template>
							<span class="iconfont icon-xiangqing ml10"></span>
						</el-tooltip>						
					</div>
					<div class="table_list">
						<statsList :staId="'02674'" :title="''" :title2="'平均数'" />
					</div>
					<div class="get_more">
						<a @click="toSeeMore('02674','发球台到果岭得分 SG: TEE-TO-GREEN')">> 查看更多</a>
					</div>
				</div>
			</div>
			<div class="shadow-line"></div>
			<!-- 4 -->
			<div class="modes">
				<div class="mode">
					<div class="title">综合得分
						<el-tooltip class="item" effect="dark" content="综合得分" placement="top-start">
							<template slot="content">
								<p class="tooltip-content">在同一球场和赛事中，球员每轮平均杆数高于或低于阵容平均值。</p>
							</template>
							<span class="iconfont icon-xiangqing ml10"></span>
						</el-tooltip>						
					</div>
					<div class="table_list">
						<statsList :staId="'02675'" :title="''" :title2="'平均数'" />
					</div>
					<div class="get_more">
						<a @click="toSeeMore('02675','综合得分 SG: TOTAL')">> 查看更多</a>
					</div>
				</div>
				<div class="mode">
					<div class="title">推杆得分
						<el-tooltip class="item" effect="dark" content="推杆得分" placement="top-start">
							<template slot="content">
								<p class="tooltip-content">根据统计基线对球员从特定距离推杆的次数进行测量，以确定球员在一个球洞上是得分还是失分。一轮比赛中所有球洞的总值减去该轮的场均得分/失分，就是该球员在该轮比赛中的得分/失分。每轮的得分累计后再除以总轮数。
									得分的概念是美巡赛Shotlink智能项目的副产品，该项目鼓励学者们对Shotlink的数据进行研究。哥伦比亚商学院的Mark Broadie教授提出了早期概念，后来由美巡赛进行了完善。</p>
							</template>
							<span class="iconfont icon-xiangqing ml10"></span>
						</el-tooltip>						
					</div>
					<div class="table_list">
						<statsList :staId="'02564'" :title="''" :title2="'平均数'" />
					</div>
					<div class="get_more">
						<a @click="toSeeMore('02564','推杆得分 SG: PUTTING')">> 查看更多</a>
					</div>
				</div>
			</div>
			<div class="shadow-line"></div>
			<!-- 5 -->
			<div class="modes">
				<div class="mode">
					<div class="title">救球成功率
						<el-tooltip class="item" effect="dark" content="救球成功率" placement="top-start">
							<template slot="content">
								<p style="font-size:16px;max-width:400px;">球员在未能标准杆上果岭的情况下，仍完成保帕或更佳成绩的百分比。</p>
							</template>
							<span class="iconfont icon-xiangqing ml10"></span>
						</el-tooltip>						
					</div>
					<div class="table_list">
						<statsList :staId="'130'" :title="''" :title2="'%'" />
					</div>
					<div class="get_more">
						<a @click="toSeeMore('130','救球成功率 SCRAMBLING')">> 查看更多</a>
					</div>
				</div>
			</div>
			<div class="shadow-line"></div>
		</div>

	</div>
</template>

<script>
	import statsList from "../../../components/tableList/statsList";

	export default {
		name: "dataForm",
		components: {
			statsList
		},
		data() {
			return {

			}

		},
		methods: {
			toSeeMore(id, title) {
				this.$router.push({
					path: '/Stats/DataTemplate',
					query: {
						staId: id,
						pageTitle: title
					}
				})
			}
		}
	}
</script>

<style scoped lang="less">
	._form {
		.Stats_title {
			/*font-weight: 500;*/
			display: flex;
			padding: 50px 50px 30px 30px;
			border-bottom: 1px solid #b3b3b3;

			h3 {
				//font-family: My_Medium;
				font-size: 30px;
			}
		}

		.outside {
			padding: 0 10px 50px 10px;

			.modes {
				width: 100%;
				display: flex;
				position: relative;
				padding-bottom: 1px;
				border-bottom-left-radius: 100%;
				border-bottom-right-radius: 100%;

				.mode {
					background-color: #ffffff;
					width: 50%;

					.title {
						font-size: 25px;
						//font-family: My_Medium;
						padding: 15px 30px 20px 30px;

						.icon-xiangqing {
							font-size: 24px;
							cursor: pointer;
							//font-family: My_Medium;
						}
					}

					.table_list {
						padding-bottom: 20px;
					}
				}

				.mode:nth-child(1) {
					margin-right: 20px;
				}
			}

			.shadow-line {
				background: url(../../../assets/images/shadow-large.png) no-repeat 50% 0;
				width: 100%;
				height: 31px;
				overflow: hidden;
				border-top: 1px solid #e6e6e6;
				flex: 1 100%;
			}

			.get_more {
				width: 100%;
				overflow: hidden;
				padding-bottom: 20px;

				a {
					cursor: pointer;
					float: right;
					font-size: 15px;
					color: #00284b;
					//font-family: My_Medium;
					text-decoration: none;
				}

				/*background-color: red;*/
			}
		}
	}
	#show :hover:after{
		cursor: pointer;
		content: attr(data-title);
	}
.tooltip-content{
		font-size:16px;
		max-width:400px;
		line-height:200%;
}

</style>
