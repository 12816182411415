<template>
<!--  数据的表格-->
  <div class="statsList">
    <table>
      <thead>
      <tr>
        <th>排名</th>
        <th>球员名称</th>
        <th v-if="title.length>0">{{title}}</th>
        <th>{{title2}}</th>
      </tr>
      </thead>
      <tbody>
        <tr :class="{'bg':index%2===0}" v-for="(item, index) in list" :key="index">
          <td>{{item.curRankTied}}{{item.curRank}}</td>
          <td>{{item.player_name}} <span class="play_En">{{item.e_name}}</span> </td>
          <td v-if="title.length>0">{{item.statValues.rndEvents}}</td>
          <td>{{item.statValues.statValue1}}</td>
        </tr>
        <tr>
          <td colspan="2">美巡赛平均数</td>
          <td v-if="title.length>0"></td>
          <td class="average">{{average}}</td>
        </tr>
      </tbody>
    </table>
   <!-- <div class="get_more" v-if="list.length>0">
     <a href="">> 查看更多</a>
   </div> -->
  </div>
</template>

<script>
  import pgaApi from "../../request/pgaApi";

  export default {
    name: "statsList",
    props:{
      staId:{
        type:String,
        default:'0'
      },
      title:{
        type:String,
        default: ''
      },
      title2:{
        type:String,
        default: ''
      }
    },
    data(){
      return {
        list:[],
        average:'',
      }
    },
    created() {
      if (this.staId.length>0)
        this.getList()
    },
    methods:{
      async getList(){
        let data = await pgaApi({
          url:'/getPgaSta',
          data:{
            year:2023,
            staId:this.staId
          }
        })
        if(data !== -1 && data!== null){
          //console.log(data)
          this.average = data.average
          this.list = data.sta.slice(0,5)
        }
        
        // console.log(this.staId);
        // console.log(this.list);
      }
    }
  }
</script>

<style scoped lang="less">
  .statsList{
     table{
       width: 100%;
       border-collapse:collapse;
       thead{
         background-image: linear-gradient(rgba(255,255,255,1) 30%,#eeeeee);
         font-size: 16px;
         th{
           //font-family: My_Bold;
           height: 40px;
           border: 1px solid #d9d9d9;
         }
         th:nth-child(2){
           width: 50%;
         }
       }
       tbody{
         font-size: 14px;
         td{
           //font-family: My_Bold;
           border: 1px solid #d9d9d9;
           height: 40px;
           text-align: center;
         }
         td:nth-child(2){
          text-align: left;
          padding-left: 20px;
          color: #003e7e;
         }
         .play_En{
           //font-family: My_Medium;
           margin-left: 10px;
           color: #000000;
         }
         .average{
           text-align: center !important;
           padding-left: 0 !important;
           color: #000000 !important;
         }
       }
     }
    .get_more{
     width: 100%;
      overflow: hidden;
      padding-top: 20px;
      a{
        float: right;
        font-size: 15px;
        color: #00284b;
        //font-family: My_Medium;
        text-decoration: none;
      }
      /*background-color: red;*/
    }
  }
  .bg{
    background-color: #f2f2f2;
  }
</style>